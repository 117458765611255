.container {
  display: block;
  grid-gap: 20px;
  height: 50vh;
  padding: 30px;
  margin-top: 0px;
  margin-left: 23vw;
  margin-right: 23vw;
  text-align: center;
}

.page {
  height: 100vh;
  overflow: auto;
  /* Center and scale the image nicely */
  background-color: rgb(231, 247, 233);
}

.input {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 25vw;
}

.divButton {
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.error {
  color: red;
}
