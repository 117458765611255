@media (orientation: landscape) {
  .container {
    display: block;
    grid-gap: 20px;
    height: 50vh;
    padding: 30px;
    margin-top: 0px;

    margin-left: 10vw;
    margin-right: 10vw;

    margin-left: 20vw;
    margin-right: 20vw;

    text-align: center;
  }
}
@media (orientation: portrait) {
  .container {
    display: block;
    grid-gap: 20px;
    height: 50vh;
    padding: 30px;
    margin-top: 0px;

    margin-left: 10vw;
    margin-right: 10vw;

    margin-left: 10vw;
    margin-right: 10vw;

    text-align: center;
  }
}
.page {
  height: 100vh;
  overflow: auto;
  /* Center and scale the image nicely */
  background-color: rgb(231, 247, 233);
}

.input {
  margin-top: 2vw;
  margin-bottom: 2vw;
  margin-left: 10px;
  margin-right: 10px;
}

.divButton {
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.error {
  color: red;
  margin-right: 10px;
}
.cardContainer {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardImage {
  height: 0px;
  width: 0px;
  border-radius: 10px;
}

.cardContent {
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardTitle {
  margin: 10px;
  font-size: medium;
  font-weight: bold;
}

.cardDescription {
  text-align: left;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 10px;
}
.cardList {
  text-align: left;
  font-size: small;
  margin-left: 10px;
}

.cardDate {
  text-align: left;
  font-size: small;
  margin-left: 10px;
  font-weight: bold;
}
.cardSubscribe {
  align-self: flex-end;
  margin: 10px;
}
.loader {
  height: 300px;
}

@media only screen and (min-width: 768px) {
  .cardImage {
    height: 230px;
    width: 230px;
    border-radius: 10px;
  }
  .cardTitle {
    margin: 10px;
    font-size: large;
    font-weight: bold;
  }

  .cardDescription {
    text-align: left;
    font-size: medium;
    margin: 10px;
  }
  .cardList {
    text-align: left;
    font-size: medium;
    margin-left: 10px;
  }
  .cardDate {
    text-align: left;
    font-size: medium;
    margin-left: 10px;
    font-weight: bold;
  }
}
