@media (orientation: landscape) {
  .container {
    display: block;
    grid-gap: 20px;
    height: 50vh;
    padding: 30px;
    margin-top: 0px;

    margin-left: 10vw;
    margin-right: 10vw;

    margin-left: 20vw;
    margin-right: 20vw;

    text-align: center;
  }
}
@media (orientation: portrait) {
  .container {
    display: block;
    grid-gap: 20px;
    height: 50vh;
    padding: 30px;
    margin-top: 0px;

    margin-left: 10vw;
    margin-right: 10vw;

    margin-left: 10vw;
    margin-right: 10vw;

    text-align: center;
  }
}
.page {
  height: 100vh;
  overflow: auto;
  /* Center and scale the image nicely */
  background-color: rgb(231, 247, 233);
}

.input {
  margin-top: 2vw;
  margin-bottom: 2vw;
  margin-left: 10px;
  margin-right: 10px;
}

.divButton {
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.error {
  color: red;
  margin-right: 10px;
}
